import React, { useState, useContext } from 'react';
import { tablet_container_maxwidth } from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';
import useWindowResize from 'pubweb-smokey/dist/hooks/useWindowResize';
import { PageContext } from '@contexts/PageContext';
import MobileNavBar from './MobileNavBar';

const MobileNavBarContainer = () => {
  const [isMobile, setIsMobile] = useState(false);
  const pageContext = useContext(PageContext);

  useWindowResize(() => {
    if (window.innerWidth > tablet_container_maxwidth) {
      setIsMobile(false);
      pageContext.actions.setMobileNavBarVisible(false);
    } else {
      setIsMobile(true);
      pageContext.actions.setMobileNavBarVisible(true);
    }
  }, [tablet_container_maxwidth]);

  return isMobile ? (
    <MobileNavBar
      sticky={
        !pageContext.state.footerInView &&
        pageContext.state.scrollDirection === 'up'
      }
      livePersonVisible={pageContext.state.livePersonVisible}
    />
  ) : null;
};

export default MobileNavBarContainer;
