// eslint-disable-next-line @typescript-eslint/no-var-requires
const colors = require('./colors');

const fontFamily = {
  sourceSans: "'source-sans-pro', Helvetica, Arial, sans-serif",
  acumin: "'acumin-pro', Helvetica, Arial, sans-serif",
};

const borders = {
  borderBlackTint: `solid 1px ${colors.primary.black.tint};`,
  borderGrey4: `solid 1px ${colors.accent.grey4}`,
  borderNavy3: `solid 1px ${colors.accent.navy3}`,
};

const transitions = {
  hoverLinkColor: 'color 400ms ease',
  hoverLinkMargin: 'margin 600ms ease',
  hoverLinkBgColor: 'background-color 450ms ease',
  rotateUp: 'rotate(180deg)',
};

const boxShadowing = {
  component: '0 6px 8px rgba(30,72,111,.15)',
  mid: '0 2px 13px 0 rgba(0, 0, 0, 0.1)',
  high: 'rgba(0, 0, 0, 0.25) 0px 0px 0px 1000px inset',
};

module.exports = {
  fontFamily,
  colors: {
    ...colors,
  },
  borders,
  transitions,
  boxShadowing,
};
