import React from 'react';
import PropTypes from 'prop-types';
import { PreFooterSectionStyles } from './PreFooterSection.styled';
import PreFooterCTA from './PreFooterCTA/PreFooterCTA';
import { getCtaProperties } from '@utils/ctaUtils';

const getHomePageCtaProperties = (cta) => {
  let ctaProps = getCtaProperties(cta);
  ctaProps.ctaSize = cta.ctaSize;
  // Set this false so the CTA code will evaluate the value for 'target' using getUrlTarget.
  ctaProps.buttonOpensToNewTab = false;

  return ctaProps;
};

// Supply domainUrls if you need to compare against something other than the chcom internalUrlVariants (for example careers domain).
// The prefooter CTA use this to determine internal link.
const getCTACards = (ctas, size, domainUrls = []) => {
  let cards = null;
  if (ctas) {
    cards = ctas
      .filter((cta) => cta?.ctaSize == size)
      .map((cta, i) => {
        return (
          <PreFooterCTA
            {...getHomePageCtaProperties(cta, i)}
            key={`${size}-${i}`}
            buttonAutomationId="cta-button-1"
            button2AutomationId="cta-button-2"
            fullWidth={
              size === 'Full' || size === 'Inset Single' ? true : false
            }
            domainUrls={domainUrls}
          />
        );
      });
  }

  if (cards.length < 1) {
    cards = null;
  }
  return cards;
};

const PreFooterSection = ({ preFooterActions, domainUrls = [] }) => {
  const fullWidthCards = getCTACards(preFooterActions, 'Full', domainUrls);
  const insetPairedCards = getCTACards(
    preFooterActions,
    'Inset Paired',
    domainUrls
  );
  const insetTripleCards = getCTACards(
    preFooterActions,
    'Inset Triple',
    domainUrls
  );
  const insetSingleCards = getCTACards(
    preFooterActions,
    'Inset Single',
    domainUrls
  );

  return (
    <PreFooterSectionStyles>
      {preFooterActions ? (
        <div className="cta-container">
          {fullWidthCards ? (
            <div className="cta-position-fullwidth">{fullWidthCards}</div>
          ) : null}
          {insetSingleCards ? (
            <div className="constrained-width">
              <div className="cta-position-inset-single">
                {insetSingleCards}
              </div>
            </div>
          ) : null}
          {insetTripleCards ? (
            <div className="constrained-width">
              <div className="cta-grid-container">
                <div className="cta-three-container">
                  <div className="cta-div left">{insetTripleCards[0]}</div>
                  <div className="cta-div right-a">{insetTripleCards[1]}</div>
                  <div className="cta-div right-b">{insetTripleCards[2]}</div>
                </div>
              </div>
            </div>
          ) : null}
          {insetPairedCards ? (
            <div className="constrained-width">
              <div className="cta-grid-container">
                <div className="cta-two-container">
                  <div className="cta-div left">{insetPairedCards[0]}</div>
                  <div className="cta-div right">{insetPairedCards[1]}</div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      ) : null}
    </PreFooterSectionStyles>
  );
};

export default PreFooterSection;

PreFooterSection.propTypes = {
  preFooterActions: PropTypes.any,
  domainUrls: PropTypes.any,
};
